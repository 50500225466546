import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';


const routes: Routes = [
  {
    path: '',
    loadChildren: './homepage/homepage.module#HomepageModule'
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: 'partnerships',
    loadChildren: './partnerships/partnerships.module#PartnershipsModule'
  },
  {
    path: 'tutorials',
    loadChildren: './tutorials/tutorials.module#TutorialsModule'
  }, {
    path: 'home-automation',
    loadChildren: './home-automation/home-automation.module#HomeAutomationModule'
  }, {
    path: 'gps',
    loadChildren: './gps/gps.module#GpsModule'
  },
  {
    path: 'about',
    loadChildren: './about/about.module#AboutModule'
  },
  {
    path: 'press',
    loadChildren: './press/press.module#PressModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
